import { render } from "./AnnualHumanResourceDuplicateDialog.vue?vue&type=template&id=07fdfa00&scoped=true"
import script from "./AnnualHumanResourceDuplicateDialog.vue?vue&type=script&lang=ts"
export * from "./AnnualHumanResourceDuplicateDialog.vue?vue&type=script&lang=ts"

import "./AnnualHumanResourceDuplicateDialog.vue?vue&type=style&index=0&id=07fdfa00&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-07fdfa00"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog});
