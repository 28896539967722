import { defineComponent } from 'vue';
import { deleteAnnualHr, getAllAnnualHr } from '@/api/dataentry-api-generated';
import AnnualHumanResourceDialog from '@/components/AnnualHumanResource/AnnualHumanResourceDialog.vue';
import AnnualHumanResourceDuplicateDialog from '@/components/AnnualHumanResource/AnnualHumanResourceDuplicateDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'AnnualHumanResources',
    components: { AnnualHumanResourceDialog, DeleteDialog, AnnualHumanResourceDuplicateDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'processorLabel',
                    name: 'processor',
                    label: 'Processor',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'yearEntry',
                    name: 'yearEntry',
                    label: 'Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'noOfPromotedFemaleSeniorLeadership',
                    name: 'noOfPromotedFemaleSeniorLeadership',
                    label: 'No of Promoted Females to Senior Leadership Positions',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfPromotedIndigenousLeadership',
                    name: 'noOfPromotedIndigenousLeadership',
                    label: 'No of Promoted/Employed Indigenous People to Leadership Positions',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'avgLengthService',
                    name: 'avgLengthService',
                    label: 'Average length of Service',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'leaveReasonOne',
                    name: 'leaveReasonOne',
                    label: 'Reason #1',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'leaveReasonTwo',
                    name: 'leaveReasonTwo',
                    label: 'Reason #2',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'leaveReasonThree',
                    name: 'leaveReasonThree',
                    label: 'Reason #3',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'leaveReasonFour',
                    name: 'leaveReasonFour',
                    label: 'Reason #4',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'leaveReasonFive',
                    name: 'leaveReasonFive',
                    label: 'Reason #5',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'isOwnPrivateRTOUsed',
                    name: 'isOwnPrivateRTOUsed',
                    label: 'Do You Use Your Own Registered Training Organisation (RTO)?',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val === true ? 'Yes' : 'No'
                },
                {
                    field: 'qualificationsOffered',
                    name: 'qualificationsOffered',
                    label: 'Qualifications Offered',
                    sortable: true,
                    align: 'center',
                    format: (val) => val.join(', ')
                },
                {
                    field: 'noOfTraineesEnrolledPerYear',
                    name: 'noOfTraineesEnrolledPerYear',
                    label: 'Total Number of Trainees and Apprentices Enrolled Per Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfTraineesCompletedPerYear',
                    name: 'noOfTraineesCompletedPerYear',
                    label: 'Total Number of Trainees and Apprentices Who Gained Qualifications for the Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalClaims',
                    name: 'totalClaims',
                    label: 'Total Number of Claims',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'avgClaimCost',
                    name: 'avgClaimCost',
                    label: 'Average Cost Per Claim',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'lostTimeFrequencyRate',
                    name: 'lostTimeFrequencyRate',
                    label: 'Lost Time Frequency Rate',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'incidentRates',
                    name: 'incidentRates',
                    label: 'Incident Rates (LTIIR)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'avgTimeLossRate',
                    name: 'avgTimeLossRate',
                    label: 'Average Time Loss Rate (ATLR)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfBbcInjShoulder',
                    name: 'noOfBbcInjShoulder',
                    label: 'Shoulder',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfBbcInjArm',
                    name: 'noOfBbcInjArm',
                    label: 'Arm',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfBbcInjBack',
                    name: 'noOfBbcInjBack',
                    label: 'Back',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfBbcInjForearmWrist',
                    name: 'noOfBbcInjForearmWrist',
                    label: 'Forearm/Wrist',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfKnivesInjAbdomen',
                    name: 'noOfKnivesInjAbdomen',
                    label: 'Abdomen',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfKnivesInjArm',
                    name: 'noOfKnivesInjArm',
                    label: 'Arm',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfKnivesInjHand',
                    name: 'noOfKnivesInjHand',
                    label: 'Hand',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfKnivesInjForearmWrist',
                    name: 'noOfKnivesInjForearmWrist',
                    label: 'Forearm/Wrist',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfLhInjAbdomen',
                    name: 'noOfLhInjAbdomen',
                    label: 'Abdomen',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfLhInjShoulder',
                    name: 'noOfLhInjShoulder',
                    label: 'Shoulder',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfLhInjArm',
                    name: 'noOfLhInjArm',
                    label: 'Arm',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfLhInjBack',
                    name: 'noOfLhInjBack',
                    label: 'Back',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfLhInjForearmWrist',
                    name: 'noOfLhInjForearmWrist',
                    label: 'Forearm/Wrist',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfNoiseInjEar',
                    name: 'noOfNoiseInjEar',
                    label: 'Ear',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfStfInjKnee',
                    name: 'noOfStfInjKnee',
                    label: 'Knee',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfStfInjShoulder',
                    name: 'noOfStfInjShoulder',
                    label: 'Shoulder',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfStfInjBack',
                    name: 'noOfStfInjBack',
                    label: 'Back',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjAbdomen',
                    name: 'noOfZoonosesInjAbdomen',
                    label: 'Abdomen',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjKnee',
                    name: 'noOfZoonosesInjKnee',
                    label: 'Knee',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjShoulder',
                    name: 'noOfZoonosesInjShoulder',
                    label: 'Shoulder',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjArm',
                    name: 'noOfZoonosesInjArm',
                    label: 'Arm',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjBack',
                    name: 'noOfZoonosesInjBack',
                    label: 'Back',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjEar',
                    name: 'noOfZoonosesInjEar',
                    label: 'Ear',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjHandFingers',
                    name: 'noOfZoonosesInjHandFingers',
                    label: 'Hand/Fingers',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noOfZoonosesInjForearmWrist',
                    name: 'noOfZoonosesInjForearmWrist',
                    label: 'Forearm/Wrist',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                }
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'processor',
                descending: true,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Annual Human Resources' : 'Annual Human Resource';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getAllAnnualHr({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Annual Human Resource', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteAnnualHr(i.uuid);
                    this.$log.addMessage('Successfully Deleted Annual Human Resource');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
