import { render } from "./AnnualHumanResourceDialog.vue?vue&type=template&id=e154077e&scoped=true"
import script from "./AnnualHumanResourceDialog.vue?vue&type=script&lang=ts"
export * from "./AnnualHumanResourceDialog.vue?vue&type=script&lang=ts"

import "./AnnualHumanResourceDialog.vue?vue&type=style&index=0&id=e154077e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-e154077e"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog});
