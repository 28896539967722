import { defineComponent } from 'vue';
import AnnualHumanResources from '@/views/AnnualHumanResource/AnnualHumanResources.vue';
export default defineComponent({
    name: 'AnnualProductionLayout',
    components: { AnnualHumanResources },
    data() {
        return {
            loading: false,
            tab: 'Annual Human Resources'
        };
    }
});
